import revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import amplitude_client_K67aUoRvbz4rFhCubhAKFc1w9V7nwLBFIRzQ_lloRRM from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/amplitude.client.ts";
import app_insights_client_6311VGiub8HCsix1YKM5TIF87vaECr2QbcU7qf9xbwQ from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/app-insights.client.ts";
import event_bus_60m5933YG1M1lE_CrIKJ5sBtLyyO8r5gNgkhCRHzmrE from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/event-bus.js";
import prototypes_rPq766MIju5oDG59X_Vqu_tYLTnNcPlMVELbH2MPNgo from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/prototypes.js";
import trpc_client_QkQ0osJIZKG1uAz8nHJq0D_tccbjoH7Idv42PBEiShE from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/trpc.client.ts";
import vue_tippy_5o_gKPL_fV58xGuVYvdYr8Aw9FF7QEfUTJpA_Bv8R10 from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/vue-tippy.js";
import vue_toastification_P9_psqZOtjv_ISwME31PUbEsPgGDfNwZ2pS2JWBq2CI from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/vue-toastification.js";
export default [
  revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY,
  unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c,
  router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8,
  payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8,
  navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34,
  check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw,
  chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0,
  plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c,
  amplitude_client_K67aUoRvbz4rFhCubhAKFc1w9V7nwLBFIRzQ_lloRRM,
  app_insights_client_6311VGiub8HCsix1YKM5TIF87vaECr2QbcU7qf9xbwQ,
  event_bus_60m5933YG1M1lE_CrIKJ5sBtLyyO8r5gNgkhCRHzmrE,
  prototypes_rPq766MIju5oDG59X_Vqu_tYLTnNcPlMVELbH2MPNgo,
  trpc_client_QkQ0osJIZKG1uAz8nHJq0D_tccbjoH7Idv42PBEiShE,
  vue_tippy_5o_gKPL_fV58xGuVYvdYr8Aw9FF7QEfUTJpA_Bv8R10,
  vue_toastification_P9_psqZOtjv_ISwME31PUbEsPgGDfNwZ2pS2JWBq2CI
]