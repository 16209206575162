<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: currentColor"
  >
    <g clip-path="url(#clip0_567_418)">
      <path
        d="M2 8.66667H7.33333V2H2V8.66667ZM2 14H7.33333V10H2V14ZM8.66667 14H14V7.33333H8.66667V14ZM8.66667 2V6H14V2H8.66667Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_567_418">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
</template>
