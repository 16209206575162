export enum CampaignState {
  INACTIVE = "inactive",
  ACTIVE = "active",
  ENDING = "ending",
  EVALUATING = "evaluating",
  EVALUATED = "evaluated",
}

export enum NotificationType {
  WELCOME = "welcome",
  CAMPAIGN_INVITED = "campaign-invited",
  CAMPAIGN_STARTED = "campaign-started",
  CAMPAIGN_JOINED = "campaign-joined",
  CAMPAIGN_ELIGIBLE = "campaign-eligible",
  CAMPAIGN_NOT_ELIGIBLE = "campaign-not-eligible",
  CAMPAIGN_ENDING = "campaign-ending",
  CAMPAIGN_ENDED = "campaign-ended",
  CAMPAIGN_MATURITY = "campaign-maturity",
  NEW_ELIGIBLE_POSTS = "posts-eligible",
  NEW_REWARDED_POSTS = "posts-rewarded",
  TRANSACTION_VERIFYING = "transaction-verifying",
  TRANSACTION_PENDING = "transaction-pending",
  TRANSACTION_CANCELLED = "transaction-cancelled",
  TRANSACTION_FAILED = "transaction-failed",
  TRANSACTION_COMPLETED = "transaction-completed",
  TERMS_CONDITIONS = "terms-and-conditions",
  AD_HOC = "ad-hoc-notification",
}
