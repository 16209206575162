
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as privacy_45policynoHZo4oNsx0HGgzRtaUjjAMag9Uz4uk9vHWHq_45_BLGMMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/privacy-policy.vue?macro=true";
import { default as termsulL6fKyT_45TLnuOS5EhzXaEXU42MzNduhNwtBAWtZzWsMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/terms.vue?macro=true";
import { default as _91id_93lofv9aMfmuwwyCRRM39dwjKUNKdcGyavZaVoSR5077cMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaign/[id].vue?macro=true";
import { default as campaignsnF97AzJtnC01zERFEgSVyqhYGHHHj1mn__bYZkUNAR8Meta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaigns.vue?macro=true";
import { default as indexC33pkdcwlN_45o9hzk_45fnAu31cQXaX1zcYIig2AEAcpNMMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/index.vue?macro=true";
import { default as deriversetalGqHDD6gdgGCHwOz_454zjSa6pQW6mWVkAmxvN7cYm0Meta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/deriverse.vue?macro=true";
import { default as marinade4y3j8h0FtKvAMcwJUxAygppKAWIDbvzdRak_Q_nf2BcMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/marinade.vue?macro=true";
import { default as metaplex3bNYonzZSaU1XsWArOGp56M1i6b9ZMnKTBE4l3SdW7UMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/metaplex.vue?macro=true";
import { default as switchboardAqwi4H9_uDWq3gJ9zKC_ohwA0fBBb2pKEWcsEFRmn1IMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/switchboard.vue?macro=true";
import { default as not_45allowedJ0sDkmfL0jEydnjYjeRcLrLxNIv78Xq3dN4acHCcrpIMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/not-allowed.vue?macro=true";
import { default as past_45campaignsBvjraDb6Z_DrAnsgDo3DujwTd_Rx6QMQ8_BFCsxWBDIMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/past-campaigns.vue?macro=true";
import { default as indexA_rrSRjYlO0C5Beoz_458RAgh3Wik7yyAulFasb_45OZ6WcMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/index.vue?macro=true";
import { default as settingscxsKDGwuuMOhrOizckvRxpCvgwJh2gv8dCmIudgTul8Meta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/settings.vue?macro=true";
import { default as verifyBQuMqe4ZDQfeQCk6ByQH6n9gP47f9LzhnNS6Q9DKUegMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/verify.vue?macro=true";
export default [
  {
    name: "articles-privacy-policy",
    path: "/articles/privacy-policy",
    meta: privacy_45policynoHZo4oNsx0HGgzRtaUjjAMag9Uz4uk9vHWHq_45_BLGMMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/privacy-policy.vue")
  },
  {
    name: "articles-terms",
    path: "/articles/terms",
    meta: termsulL6fKyT_45TLnuOS5EhzXaEXU42MzNduhNwtBAWtZzWsMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/terms.vue")
  },
  {
    name: "campaign-id",
    path: "/campaign/:id()",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaign/[id].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaigns.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/index.vue")
  },
  {
    name: "landing-deriverse",
    path: "/landing/deriverse",
    meta: deriversetalGqHDD6gdgGCHwOz_454zjSa6pQW6mWVkAmxvN7cYm0Meta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/deriverse.vue")
  },
  {
    name: "landing-marinade",
    path: "/landing/marinade",
    meta: marinade4y3j8h0FtKvAMcwJUxAygppKAWIDbvzdRak_Q_nf2BcMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/marinade.vue")
  },
  {
    name: "landing-metaplex",
    path: "/landing/metaplex",
    meta: metaplex3bNYonzZSaU1XsWArOGp56M1i6b9ZMnKTBE4l3SdW7UMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/metaplex.vue")
  },
  {
    name: "landing-switchboard",
    path: "/landing/switchboard",
    meta: switchboardAqwi4H9_uDWq3gJ9zKC_ohwA0fBBb2pKEWcsEFRmn1IMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/switchboard.vue")
  },
  {
    name: "not-allowed",
    path: "/not-allowed",
    meta: not_45allowedJ0sDkmfL0jEydnjYjeRcLrLxNIv78Xq3dN4acHCcrpIMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/not-allowed.vue")
  },
  {
    name: "past-campaigns",
    path: "/past-campaigns",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/past-campaigns.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/index.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/settings.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyBQuMqe4ZDQfeQCk6ByQH6n9gP47f9LzhnNS6Q9DKUegMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/verify.vue")
  }
]