import { useToast } from "vue-toastification";

export function successMessage(message, atts = {}) {
  useToast().success(message, atts);
}

export function errorMessage(e, atts = {}, persist = false) {
  let errorMessage = "Error, please contact support.";

  // Handle error message as a simple string
  if (typeof e === "string") {
    errorMessage = e;
  }

  // Check for and handle structured errors from an HTTP response
  let errors = e?.response?.data?.errors;
  if (errors && errors.length) {
    errorMessage = errors.join(" ");
  }

  // Check for a generic message from the response
  let resErrorMessage = e?.response?.data?.message;
  if (resErrorMessage) {
    errorMessage = resErrorMessage;
  }

  // Specifically handle Python validation errors
  let validationErrors = e?.response?.data?.detail || e?.response?.data?.errors; // Adjust based on your error structure
  if (validationErrors && Array.isArray(validationErrors)) {
    errorMessage = validationErrors
      .map((err) => {
        // Format the error message to include the location and message
        const location = err.loc.join(".");
        return `${location}: ${err.msg}`;
      })
      .join(" ");
  }

  // Display the error message using your toast notification system
  if (persist) {
    atts.timeout = 0;
  }
  useToast().error(errorMessage, atts);
}
