import {
  DefaultApi,
  type AddWalletDto,
  type CompleteWalletVerificationDto,
  type CreatePostRequestAttachmentDto,
  type CreatePostRequestDto,
  type JoinCampaignRequestDto,
  type StartWalletVerificationDto,
  type TwoFactorVerificationRequest,
  type UpdateUserBasicSettingsDto,
  type UpdateUserEmailDto,
  type UpdateUserTwoFactorEnabledDto,
  type WithdrawRequestDto,
} from "../../_api_generated_/";

export default {
  getApiClient() {
    return new DefaultApi(undefined, undefined, useAxios());
  },

  getTwitterLoginUrl(deviceId?: string) {
    const currentOrigin = window.location.origin;
    const callbackUrl = currentOrigin + "/";
    return this.getApiClient().authControllerTwitterReadLogin(
      callbackUrl,
      callbackUrl + "?login=cancelled",
      deviceId,
    );
  },

  getTwitterAuthorLoginUrl() {
    const currentOrigin = window.location.origin;
    const callbackUrl = currentOrigin + "/";
    return this.getApiClient().authControllerTwitterWriteLogin(
      callbackUrl,
      callbackUrl + "?login=cancelled",
    );
  },

  getCurrentUser() {
    return this.getApiClient().authControllerGetProfile();
  },

  verifyTransaction(
    withdrawalId: string,
    payload: TwoFactorVerificationRequest,
  ) {
    return this.getApiClient().withdrawalControllerVerifyWithdrawal(
      withdrawalId,
      payload,
    );
  },

  sendTransactionCodeAgain({ withdrawalId }: { withdrawalId: string }) {
    return this.getApiClient().withdrawalControllerVerifyWithdrawalResend(
      withdrawalId,
    );
  },

  getDashboard() {
    return this.getApiClient().dashboardPageControllerGetDashboardPage();
  },

  getProfile() {
    return this.getApiClient().profilePageControllerGetProfilePage();
  },

  getCampaign(campaignId: string) {
    return this.getApiClient().campaignPageControllerGetCampaignDetail(
      campaignId,
    );
  },

  joinCampaign(campaignId: string, data: JoinCampaignRequestDto) {
    return this.getApiClient().joinCampaignControllerJoinCampaign(
      campaignId,
      data,
    );
  },

  submitTweet(
    content = "",
    attachments: CreatePostRequestAttachmentDto[] = [],
    campaignId = "",
  ) {
    const postRequest: CreatePostRequestDto = {
      content: content,
      attachments: attachments,
      campaignId: campaignId,
    };
    return this.getApiClient().actionControllerCreatePost(postRequest);
  },

  saveWallet(payload: AddWalletDto) {
    return this.getApiClient().walletControllerAddWallet(payload);
  },

  startWalletVerification(payload: StartWalletVerificationDto) {
    return this.getApiClient().walletControllerStartWalletVerification(payload);
  },
  completeWalletVerification(payload: CompleteWalletVerificationDto) {
    return this.getApiClient().walletControllerCompleteWalletVerification(
      payload,
    );
  },

  claimTweet(postId: string) {
    return this.getApiClient().actionControllerClaimPost(postId);
  },

  hideTweet(tweetId: string) {
    return this.getApiClient().actionControllerHidePost(tweetId);
  },

  getInvitationDetails(publicToken: string) {
    return this.getApiClient().invitationPageControllerGetInvitationPage(
      publicToken,
    );
  },

  getTransactions(currency?: string) {
    return this.getApiClient().withdrawalControllerGetWithdraws(currency);
  },

  getWallets(currency?: string) {
    return this.getApiClient().walletControllerGetWallets(currency);
  },

  makeWithdraw(payload: WithdrawRequestDto) {
    return this.getApiClient().withdrawalControllerRequestWithdraw(payload);
  },

  refreshNotifications() {
    return this.getApiClient().notificationControllerGetNotifications();
  },

  markAsSeen(notificationId: string) {
    return this.getApiClient().notificationControllerMarkNotificationRead(
      notificationId,
    );
  },

  markAllAsSeen() {
    return this.getApiClient().notificationControllerMarkAllNotificationsRead();
  },

  saveBasicSettings(payload: UpdateUserBasicSettingsDto) {
    return this.getApiClient().userSettingsBasicControllerUpdateBasicSettings(
      payload,
    );
  },

  disconnectWallet(walletId: string) {
    return this.getApiClient().walletControllerRemoveWallet(walletId);
  },

  cancelTransaction(withdrawalId: string) {
    return this.getApiClient().withdrawalControllerCancelWithdraw(withdrawalId);
  },

  loadPaychecks() {
    return this.getApiClient().payoutControllerGetPendingPayouts();
  },

  claimAllRewards(campaignId: string) {
    return this.getApiClient().actionControllerConfirmPayout(campaignId);
  },

  getWalletTypes() {
    return this.getApiClient().walletControllerGetWalletTypes();
  },

  setTwoFactor(payload: UpdateUserTwoFactorEnabledDto) {
    return this.getApiClient().userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(
      payload,
    );
  },

  verifyTwoFactor(otp: TwoFactorVerificationRequest) {
    return this.getApiClient().userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(
      otp,
    );
  },

  setUserEmail(payload: UpdateUserEmailDto) {
    return this.getApiClient().userSettingsEmailControllerRequestUserEmailChange(
      payload,
    );
  },

  verifyUserEmail(otp: TwoFactorVerificationRequest) {
    return this.getApiClient().userSettingsEmailControllerApproveUserEmailChange(
      otp,
    );
  },
};
