<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 16C3.58172 16 -5.4282e-07 12.4183 -3.49691e-07 8C-1.56562e-07 3.58172 3.58172 -5.4282e-07 8 -3.49691e-07C12.4183 -1.56562e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM14.3158 8C14.3158 10.9223 12.3311 13.381 9.63605 14.1018C9.32379 14.1853 9.03295 13.938 9.03295 13.6147L9.03295 13.0911C9.03295 13.091 9.03286 13.0909 9.03277 13.0909C9.03267 13.0909 9.03258 13.0908 9.03258 13.0907L9.03258 8.00018C9.03258 8.00008 9.03267 8 9.03277 8C9.03287 8 9.03295 7.99992 9.03295 7.99982L9.03295 7.79079C9.03295 7.34385 8.65849 6.98153 8.19658 6.98153C7.73467 6.98153 7.36022 7.34385 7.36022 7.79079L7.36022 9.86391C7.36022 10.3841 6.86769 10.7709 6.42792 10.493C5.60149 9.97091 5.05262 9.04929 5.05262 7.99948C5.05262 6.37169 6.3722 5.05211 7.99999 5.05211C9.62778 5.05211 10.9474 6.37169 10.9474 7.99948C10.9474 8.0366 10.9467 8.07356 10.9453 8.11035L10.9453 9.84834C10.9453 10.5362 11.7093 10.8573 12.0451 10.257C12.4187 9.58906 12.6316 8.81913 12.6316 7.99948C12.6316 5.44153 10.5579 3.3679 7.99999 3.3679C5.44204 3.3679 3.36841 5.44153 3.36841 7.99948C3.36841 10.0944 4.75931 11.8645 6.66804 12.4367C7.05722 12.5533 7.36022 12.8898 7.36022 13.2961L7.36022 13.3843C7.36022 13.8658 6.94191 14.2462 6.47456 14.1303C3.72341 13.448 1.68421 10.9621 1.68421 8C1.68421 4.51189 4.51189 1.68421 8 1.68421C11.4881 1.68421 14.3158 4.51189 14.3158 8Z"
    />
  </svg>
</template>
