<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    xmlns="http://www.w3.org/2000/svg"
    style="fill: currentColor"
  >
    <path
      d="M6 14.625C6.825 14.625 7.5 13.95 7.5 13.125H4.5C4.5 13.95 5.1675 14.625 6 14.625ZM10.5 10.125V6.375C10.5 4.0725 9.27 2.145 7.125 1.635V1.125C7.125 0.5025 6.6225 0 6 0C5.3775 0 4.875 0.5025 4.875 1.125V1.635C2.7225 2.145 1.5 4.065 1.5 6.375V10.125L0 11.625V12.375H12V11.625L10.5 10.125Z"
    />
  </svg>
</template>
