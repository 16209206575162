import { useQuery } from "@tanstack/vue-query";

export function useCanCreatePost() {
  const { $trpc } = useNuxtApp();
  const { data: campaigns } = useQuery({
    // same parameters as on dashboard, so this will be shared with the dashboard
    queryKey: ["listCurrentCampaigns", { limit: 2 }],
    queryFn: () => $trpc.listCurrentCampaigns.query({ limit: 2 }),
    staleTime: 1000, // 1 second, to avoid refetching on same page
  });

  return computed(() => campaigns.value && campaigns.value.length > 0);
}
