<script setup lang="ts"></script>

<template>
  <svg
    width="24"
    height="10"
    viewBox="0 0 24 10"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="2" />
    <rect y="8" width="24" height="2" />
  </svg>
</template>

<style scoped></style>
