import axios from "axios";

export default function () {
  const config = useRuntimeConfig();
  const baseUrl = config.public.API_ENDPOINT;

  const apiClient = axios.create({
    baseURL: baseUrl,
    timeout: 15 * 1000,
    headers: {
      common: {
        "If-None-Match": null,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    },
  });

  const authClient = axios.create({
    baseURL: baseUrl,
    timeout: 15 * 1000,
    headers: {
      common: {
        "If-None-Match": null,
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    },
  });

  let subscribers = [];
  let isAlreadyFetchingAccessToken = false;

  function onAccessTokenFetched(accessToken) {
    subscribers.forEach((callback) => callback(accessToken));
    subscribers = [];
  }

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  function rejectSubscribers(error) {
    subscribers.forEach((callback) => callback(null, error));
    subscribers = [];
  }

  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      if (config?.headers?.["If-None-Match"]) {
        delete config.headers["If-None-Match"];
      }

      if (
        window.appInsights &&
        window.appInsights.context &&
        window.appInsights.context.telemetryTrace
      ) {
        config.headers["X-Operation-Id"] =
          window.appInsights.context.telemetryTrace.traceID;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  authClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      if (
        window.appInsights &&
        window.appInsights.context &&
        window.appInsights.context.telemetryTrace
      ) {
        config.headers["X-Operation-Id"] =
          window.appInsights.context.telemetryTrace.traceID;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const { config, response } = error;
      const originalRequest = config;

      if (response && response.status === 401) {
        if (response?.data?.message && response.data.message.startsWith("X:")) {
          clearTokens();
          window.location.reload();
        }

        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          return fetchNewAccessTokenWithRefreshToken()
            .then((newAccessToken) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(newAccessToken);
              return apiClient(originalRequest); // Retry original request with new token
            })
            .catch((e) => {
              clearTokens();
              rejectSubscribers(e); // Notify all subscribers of failure
              window.location.reload();
              return Promise.reject(e); // Reject the original request with error
            });
        }

        return new Promise((resolve, reject) => {
          addSubscriber((accessToken, err) => {
            if (err) {
              reject(err);
            } else {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              resolve(apiClient(originalRequest)); // Retry original request with new token
            }
          });
        });
      }

      if (response && response.status > 500) {
        showError({
          status: response.status,
          statusMessage: response?.data?.message,
        });
      }

      return Promise.reject(error);
    },
  );

  function fetchNewAccessTokenWithRefreshToken() {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorage.getItem("refresh_token");
      const accessToken = localStorage.getItem("access_token");

      if (!refreshToken) {
        reject(new Error("No refresh token available"));
        return;
      }

      authClient
        .post("/auth/token", {
          refreshToken,
          accessToken,
        })
        .then((response) => {
          const { accessToken, refreshToken: newRefreshToken } = response.data;
          setTokens(accessToken, newRefreshToken);
          resolve(accessToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  return apiClient;
}
