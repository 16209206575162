// Universal Communication Manager for Cross-Domain Data Transfer
export default function useDomainCommunication() {
  const runtimeConfig = useRuntimeConfig();
  const coreDomainUrl = new URL(runtimeConfig.public.CORE_SPA_DOMAIN);
  const coreDomainOrigin = coreDomainUrl.origin;
  const coreDomainHostname = coreDomainUrl.hostname; // e.g., "localhost"
  const guestDomains = runtimeConfig.public.GUEST_DOMAINS; // Convert guest domains to an array

  // Check if the current domain is a guest or the core domain
  const isGuestDomain = () => {
    return guestDomains.includes(window.location.origin);
  };

  const isCoreDomain = () => {
    return window.location.origin === coreDomainOrigin;
  };

  // Universal method to handle cross-domain updates
  const crossDomainUpdate = (key, value) => {
    // Save to local storage on the current domain
    localStorage.setItem(key, value);

    // Determine if current domain is guest or core
    if (isGuestDomain()) {
      // If guest, send the message to core
      sendMessageToCore({ key, value });
    } else if (isCoreDomain()) {
      // If core, send the message to all guests
      sendMessageToGuests({ key, value });
    }
  };

  // Send message from guest to core
  const sendMessageToCore = (message) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = coreDomainOrigin; // Set iframe to core domain's origin
    document.body.appendChild(iframe);

    iframe.onload = () => {
      // Use postMessage without trying to access cross-origin properties
      iframe.contentWindow.postMessage(message, coreDomainOrigin);
      // Remove iframe after message is sent to clean up
      document.body.removeChild(iframe);
    };
  };

  // Send message from core to all guests
  const sendMessageToGuests = (message) => {
    guestDomains.forEach((guestDomain) => {
      const guestUrl = new URL(guestDomain); // Convert guest domain to URL object
      const guestOrigin = guestUrl.origin; // Get the origin (protocol + hostname + port)

      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = guestOrigin; // Set iframe to guest domain's origin
      document.body.appendChild(iframe);

      iframe.onload = () => {
        // Use postMessage without trying to access cross-origin properties
        iframe.contentWindow.postMessage(message, guestOrigin);
        // Remove iframe after message is sent to clean up
        document.body.removeChild(iframe);
      };
    });
  };

  // Set up universal receiver to listen for incoming messages
  const setupUniversalReceiver = () => {
    window.addEventListener("message", (event) => {
      const allowedOrigins = [
        coreDomainOrigin,
        ...guestDomains.map((domain) => new URL(domain).origin),
      ];

      // Check if the origin of the message is allowed
      if (!allowedOrigins.includes(event.origin)) {
        console.warn("Received message from unknown origin:", event.origin);
        return;
      }

      // Extract the message and save it to local storage
      const { key, value } = event.data;
      if (key && value !== undefined) {
        localStorage.setItem(key, value);
      }
    });
  };

  return {
    isGuestDomain,
    isCoreDomain,
    crossDomainUpdate,
    setupUniversalReceiver,
  };
}
