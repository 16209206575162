import type { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createTRPCProxyClient, httpLink } from "@trpc/client";
import { VueQueryPlugin } from "@tanstack/vue-query";
import type { CreatorRouter } from "evangelist-api/src/creatorRouter";
export type {
  CreatorRouterInput as RouterInput,
  CreatorRouterOutput as RouterOutput,
} from "evangelist-api/src/creatorRouter";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  nuxtApp.vueApp.use(VueQueryPlugin);

  const trpc = createTRPCProxyClient<CreatorRouter>({
    links: [
      // not using httpBatchLink:
      // Clients connect using HTTP/2, and then Azure sends it to Container App as HTTP/1.1
      // So we don't really get any speedup from batching on the clients, and not batching makes debugging simpler.
      httpLink({
        url: String(config.public.TRPC_URL),
        async headers() {
          function authHeaders() {
            const token = localStorage.getItem("session_token");
            if (token) {
              return { authorization: `Bearer ${token}` };
            }
            return {};
          }

          function traceHeaders() {
            // I don't know how to access the appInsights helper without typescript complaining about definition using itself
            // const { $appInsights } = useNuxtApp();
            const appInsights: ApplicationInsights | undefined =
              // @ts-expect-error
              window.appInsights;

            if (
              appInsights &&
              appInsights.context &&
              appInsights.context.telemetryTrace
            ) {
              return {
                "x-operation-id": appInsights.context.telemetryTrace.traceID,
              };
            } else {
              return {};
            }
          }

          return {
            ...authHeaders(),
            ...traceHeaders(),
          };
        },

        fetch: async (url, options): Promise<Response> => {
          const res = await fetch(url, options);

          // Log out if we get a 401
          if (res.status === 401) {
            clearTokens();
            window.location.reload();
            return new Response("Unauthorized", { status: 401 });
          } else {
            return res;
          }
        },
      }),
    ],
  });

  return {
    provide: {
      trpc,
    },
  };
});
