import { isChromeOnIOS } from "~/utils/common.js";

export function useFixedFullscreen() {
  let observer = null;
  const observedElements = new Set();

  const chromeOnIos = isChromeOnIOS();

  // Function to update the top position of each fixed-fullscreen element
  const updateFixedFullscreenPosition = () => {
    observedElements.forEach((el) => {
      el.style.position = "absolute";
      el.style.top = `${window.scrollY}px`; // Set top position based on scroll
      el.style.left = "0";
      el.style.width = "100%";

      el.style.height = window.innerHeight + 40 + "px"; // Set height to fill the screen on Chrome on iOS
    });
  };

  // Function to add element to the observed set and set initial styles
  const addElement = (el) => {
    if (!observedElements.has(el)) {
      observedElements.add(el);
      updateFixedFullscreenPosition(); // Initial update when element is detected
    }
  };

  // Function to remove element from the observed set
  const removeElement = (el) => {
    observedElements.delete(el);
  };

  // Initialize MutationObserver to track added or removed elements
  const observeDOM = () => {
    observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (
            node.nodeType === 1 &&
            node.classList.contains("fixed-fullscreen")
          ) {
            addElement(node); // Add the element if it has the fixed-fullscreen class
          }
        });

        mutation.removedNodes.forEach((node) => {
          if (
            node.nodeType === 1 &&
            node.classList.contains("fixed-fullscreen")
          ) {
            removeElement(node); // Remove the element if it was removed from DOM
          }
        });
      });
    });

    // Observe changes in the body and its descendants
    observer.observe(document.body, { childList: true, subtree: true });
  };

  if (chromeOnIos) {
    document.querySelector("body").classList.add("body--ios-chrome");
    // console.log("installing workaround for Chrome ios")
    onMounted(() => {
      observeDOM(); // Start observing DOM changes
      window.addEventListener("scroll", updateFixedFullscreenPosition);
      window.addEventListener("resize", updateFixedFullscreenPosition);
      window.addEventListener(
        "orientationchange",
        updateFixedFullscreenPosition,
      );
    });

    onBeforeUnmount(() => {
      // Disconnect observer and clean up event listeners
      if (observer) observer.disconnect();
      window.removeEventListener("scroll", updateFixedFullscreenPosition);
      window.removeEventListener("resize", updateFixedFullscreenPosition);
      window.removeEventListener(
        "orientationchange",
        updateFixedFullscreenPosition,
      );
    });
  }
}
